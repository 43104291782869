import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';


import './App.css';

const LETTERS = {
  1: "!",
  2: "G",
  3: "E",
  4: "T",
  5: "!",
  6: "!",
  7: "T",
  8: "H",
  9: "E",
  10: "!",
  11: "!",
  12: "D",
  13: "C",
  14: "K",
  15: "!",
  16: "!",
  17: "O",
  18: "U",
  19: "T",
  20: "!"
}

const FILES = {
    1: 'bc119f2c-16fe-40f5-8ba1-85133446f0f3',
    2: 'f426ae54-8288-4320-9ad0-dfa688091122',
    3: 'f5b0d751-231c-4da4-9700-1af01bd0b5c4',
    4: '7e67a580-f167-4c14-a035-e7858dd5714b',
    5: '83d1700d-fded-4d5f-973e-9ad9ae26761b',
    6: 'f2b445e4-24b5-4a13-b278-0224042cc548',
    7: 'b60770ba-7a2f-4e1e-8ab8-39ac3d7f9bea',
    8: '41221678-ec19-430e-a745-77f4b5772f38',
    9: '13fd81cc-e85e-4110-8ab7-8e044ccb8e6a',
    10: '0a3de91c-eeaf-4b29-92ee-98bdd943d515',
    11: '87a3f8ec-f9e1-4978-979b-9821d9168384',
    12: '428eb929-6610-4d04-91cb-b9723ad10e44',
    13: '8996da56-3667-40bb-b429-699265a87173',
    14: '57d3d00e-66e3-468e-912e-1f20c4dc2365',
    15: '0857c6b4-64bc-4cc5-8b61-a880c12b1b18',
    16: 'd9f064d0-787e-4a53-a21b-3cd0f1f6e00c',
    17: 'e5beed55-7573-4f61-b421-954bf8b73b55',
    18: '6373d6aa-d170-4317-89ca-8cd2cc1d68f9',
    19: 'bfa0237b-010c-43e0-a856-d8a983d9143d',
    20: 'f20fd286-eca1-4ffd-bb48-6bc93925fe54',
}

const DESC = {
  1: "Please look up Four Seasons Total Landscaping if you haven’t already. Be Best was Melania’s anti-bullying campaign.",
  2: "Trump rally with empty seats and a balloon drop (never mind, it’s COVID)",
  3: "Trump-scarecrow voter intimidation",
  4: "Count the votes! Then stop the count!",
  5: "I included states that gave some of all of their electoral votes to Biden",
  6: "To Bleach Their Own",
  7: "Again! And Again! Did you know Biden bought keepamericagreat.com?",
  8: "Wonder if Trump will still be involved with the Miss America pageants",
  9: "Can you believe this was the initial Trump Pence logo?",
  10: "Sorry to my white male friends- I didn’t mean to single you out. If you’re in a militia, I guess I don’t actually know you very well...",
  11: "Did you know Trump has a button on his desk for Diet Coke?",
  12: "Mike Pence with a fly on his head during the VP debates",
  13: "Trump baby balloon- this flew in London!",
  14: "Check out the NYTimes Trump tax findings",
  15: "Remember when he looked directly at the solar eclipse?",
  16: "Had to include Gateau",
  17: "This basketball team would probably not be successful.",
  18: "Check out the covfefe tweet",
  19: "Cheetos! Tweetos!",
  20: "Remember when he had toilet paper on his shoe?"
}

function getCalendarNum(calendarNum, referenceDate, clickHandler) {
  const past = referenceDate >=  new Date(`January ${calendarNum} 2021`)
  return  past ? (
    <Grid class="CalDate" item>
      <Typography class="PastDate" onClick={() => clickHandler(calendarNum)}  variant="h2">
        {LETTERS[calendarNum]}
      </Typography>   
    </Grid>
  ) : (
    <Grid class="CalDate" item>
      <Typography class="FutureDate" variant="h2">
        {calendarNum}
      </Typography>   
    </Grid>
  )
}

function getDuck(flip, rotateDeg) {
  return (
    <Tooltip placement="bottom" title="i'm a quack">
      <img 
        style={{"transform": `${flip ? " scaleX(-1)" : ""} rotate(${rotateDeg}deg)`}}
        width={150} src={"duck.png"}
        alt="lame duck"
      />
    </Tooltip>
  )
}

export default function Calendar() {
    const today = new Date();
    const referenceDate =  new Date(`${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`)
    const [openImage, setOpenImage] = useState(null)
    return (
        <Container class = "Body">
            <div class="Column">
                {getDuck(true, -15)}
                {getDuck(true, 0)}
                {getDuck(true, 15)}
            </div>

            <div class="Calendar">
                <h1 style={{"text-align": "center"}}>inauguration day advent calendar</h1>
                <h2 style={{"text-align": "center"}}>january 2021</h2>
                {[0, 1, 2, 3].map((row) => 
                <Grid style={{"margin": "10px"}} container justify="center" spacing={5}>
                    {
                    [1, 2, 3, 4, 5].map((col) => 
                    getCalendarNum(row * 5 + col, referenceDate, setOpenImage)
                    )}
                </Grid>
                )}
            </div>
            <div class="Column">
                {getDuck(false, 15)}
                {getDuck(false, 0)}
                {getDuck(false, -15)}
            </div>
            <Modal
                open={openImage !== null}
                onClose={() => setOpenImage(null)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Card class="Center">
                <img width={500} src={`${FILES[openImage]}.png`} alt="uh oh" />
                <CardContent class="Comment">{DESC[openImage]}</CardContent>
                </Card>
            </Modal>
        </Container>
    )
}