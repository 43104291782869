import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import './App.css';
const POEM = [
    "I met a developer from a bubbling land",
    "Who said -“One vast and headless horse of stone",
    "Lies in the valley…Near it, on the sand,",
    "Half sunk a shattered visage lies, whose smile",
    "And weathered horn, and air of cash in hand",
    "Tell that its sculptor well those passions read",
    "Which yet survive, stampede on these lifeless things",
    "The eyes that spied, and the tongue that misled;",
    "And on the pedestal, these words appear:",
    "My name is Coder-man-deus, King of Kings",
    "Look on my Works, ye Mighty, and despair!",
    "Nothing beside remains. Round the decay",
    "Of that technical Debt, boundless and bare",
    "The TODO comments stretch over the bay.”",
]
export default function Other() {
    return (
        <Container>
            <Typography style={{"text-align": "center"}} variant="h5" color="inherit" noWrap>
            Coder-man-deus
            </Typography>
            {POEM.map((l) => <p style={{"width": "500px", "font-style": "italic"}}> {l} </p>)
            }
            <img style={{"width": "300px"}} src="https://www.mercurynews.com/wp-content/uploads/2018/04/burning_06.jpg?w=620" alt="unicorn"/>
            <Typography style={{"text-align": "center", "padding-top": "50px"}} variant="h5" color="inherit" noWrap>
            the real life advent calendar
            </Typography>
            <img width={500} src={"real-1.png"} alt={"real-1"}/>
            <p style={{"width": "500px"}}>
            I created six of these frames for friends - each packet contains a small chocolate and a drawing.
            </p>
        </Container>
    )
}