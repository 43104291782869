import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import './App.css';

function comic(imgSrc, imgAlt, tagline, description) {
    return (
        <div style={{"padding-top": "20px", "border-bottom": "navy dotted 1px"}}>
            <Typography style={{"text-align": "center"}} variant="h5" color="inherit" noWrap>
                {tagline}
            </Typography>
            <img width={800} src={imgSrc} alt={imgAlt}/>
            {tagline && 
                <p style={{"width": "800px"}}>{description}</p>
            }
        </div>
    )
}

export default function Comics() {
    return (
        <Container>
            {comic(
                "you-fucked-up.png",
                "you-fucked-up",
                "you fucked up",
                null,
            )}
            {comic(
                "gram.png",
                "gram",
                "do it for the gram",
                null,
            )}
        </Container>
    )
}