import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import './App.css';

export default function About() {
    return (
        <Card class="About">
            <Typography style={{"text-align": "center"}} variant="h5" color="inherit" noWrap>
              about the artist
            </Typography>
            <div class="AboutDesc">
                <img style={{"width": "300px"}} src="gato.png" alt="gato"/>
                <div style={{"padding-left": "20px"}}>
                    <p>
                        Gateau is a San Francisco based kitty.
                        This is her first digital sketch project.
                    </p>
                    <p>
                        The advent calendar is a countdown to inauguration day 2021
                        that contains a digital sketch per day.
                    </p>
                    
                </div>
            </div>
        </Card>
    )

}