import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import About from "./About"
import Calendar from "./Calendar"
import Comics from "./Comics"
import Other from "./Other"

import './App.css';


function App() {
  
  return (
    <Router>
      <div className="App">
        <AppBar position="fixed" color="default">
          <Toolbar class="ToolBar">
            <div class="Links">
              <Typography style={{"padding-right": "20px"}} variant="h6" color="inherit" noWrap>
                <Link to="/about">about</Link>
              </Typography>
              
              <Typography style={{"padding-right": "20px"}} variant="h6" color="inherit" noWrap>            
                <Link to="/">advent calendar</Link>
              </Typography>

              <Typography style={{"padding-right": "20px"}} variant="h6" color="inherit" noWrap>            
                <Link to="/comics">comics</Link>
              </Typography>

              <Typography variant="h6" color="inherit" noWrap>            
                <Link to="/other">other</Link>
              </Typography>
            </div>
            <Typography variant="h5" color="inherit" noWrap>
              nine 5s - mostly available!
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{"padding-top": "100px"}}>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/comics">          
              <Comics />
            </Route>
            <Route path="/other">
              <Other />
            </Route>
            <Route path="/">
              <Calendar />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;